<template>
  <div
    component-name="videoplayer-youtube"
    ref="tpl_root"
    class="aspect-16/9 bg-skin-base"
    v-surfer="{
      setup: {
        debug: true,
      },
      observers: {
        init: true,
        visiblePartially: { event: true },
        offscreen: { event: true },
      },
    }"
    @surfer-visible-partially="playVideo"
    @surfer-offscreen="pauseVideo"
  >
    <div :id="playerID" class="w-full h-full"></div>
  </div>
</template>

<script setup>
const props = defineProps({
  videoId: {
    type: String,
    required: true,
  },
  useLoop: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const playerReady = ref(false);
const playerState = ref("still has to play");
const playerID = useId().replace(/[^a-zA-Z0-9]/g, "_");
const route = useRoute();
const tpl_root = templateRef("tpl_root");
const resume = ref(true);
let player;

function initPlayer() {
  player = new YT.Player(playerID, {
    videoId: props.videoId,
    playerVars: {
      showinfo: 0,
      enablejsapi: 1,
      playsinline: 1,
      hl: route.params.lang,
      cc_lang_pref: route.params.lang,
      rel: 0,
      loop: props.useLoop,
      color: "white",
    },
    events: {
      onStateChange: onPlayerStateChange,
      onReady: () => {
        playerReady.value = true;
      },
    },
  });
}

function playVideo() {
  if (player && playerReady.value && resume.value === true) {
    resume.value = false;
    player.playVideo();
  }
}

function pauseVideo() {
  if (player && playerReady.value) {
    resume.value = playerState.value === YT.PlayerState.PLAYING;
    player.pauseVideo();
  }
}

function onPlayerStateChange(event) {
  playerState.value = event.data;
}

useSafeMountedEl(tpl_root, () => {
  window.YT
    ? initPlayer()
    : window.addEventListener("ytIframeAPILoaded", initPlayer, { once: true });
});

onUnmounted(() => {
  if (window.YT && player) {
    player.destroy();
    player = null;
  } else {
    window.removeEventListener("ytIframeAPILoaded", initPlayer);
  }
});
</script>
