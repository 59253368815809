<template>
  <videoplayer-youtube v-if="useYoutube" />
  <videoplayer-custom v-else />
</template>

<script setup>
const props = defineProps({
  useYoutube: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>
