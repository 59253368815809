<template>
  <div
    data-skin="black"
    component-name="videoplayer-custom"
    class="@container/videoplayer-custom"
  >
    <div ref="tpl_target" class="grid grid-cols-1 text-skin-base">
      <video
        :crossorigin="
          /^thron$|^cloudinary$/.test(cdn) ? 'crossorigin' : undefined
        "
        :title="title"
        :src="finalSrc"
        :width="width"
        :height="height"
        :poster="finalPoster"
        :loop="useLoop || undefined"
        ref="tpl_video"
        playsinline
        disablePictureInPicture
        disableremoteplayback
        x-webkit-airplay="deny"
        controlsList="nodownload nofullscreen noremoteplayback"
        class="w-full col-start-1 col-end-2 row-start-1 row-end-2"
        :class="{
          'aspect-4/5': aspectRatio === '4 / 5',
          'aspect-1/1': aspectRatio === '1 / 1',
          'aspect-3/1': aspectRatio === '3 / 1',
          'aspect-3/2': aspectRatio === '3 / 2',
          'aspect-16/9': aspectRatio === '16 / 9',
          'bg-skin-base': useBg,
          'object-cover': fit === 'cover',
          'object-contain': fit === 'contain',
        }"
        @click="onPlayPauseClick"
      ></video>

      <footer
        class="z-10 flex items-end w-full col-start-1 col-end-2 row-start-1 row-end-2 pointer-events-none"
        :class="{ invisible: hideControls }"
      >
        <!-- PLAYER -->
        <div
          class="flex gap-ui w-full py-1 @[600px]/videoplayer-custom:py-md @[900px]/videoplayer-custom:py-xl pointer-events-auto px-edge bg-black/10"
        >
          <!-- BLOCK PLAYER LEFT -->
          <div class="flex items-center text-white gap-ui">
            <!-- PLAY/PAUSE -->
            <btn-icon
              :icon="playing ? 'pause' : 'play'"
              title="play-pause"
              variant="naked"
              @click="onPlayPauseClick"
            />
            <!-- end PLAY/PAUSE -->

            <!-- VOLUME/MUTE -->
            <btn-icon
              :icon="muted ? 'audio-off' : 'audio-on'"
              title="audio-on-off"
              variant="naked"
              @click="muted = !muted"
            />
            <!-- end VOLUME/MUTE -->
          </div>
          <!-- end BLOCK PLAYER LEFT -->

          <!-- BLOCK PLAYER CENTER -->
          <div class="flex items-center w-full text-white gap-ui">
            <section
              ref="tpl_seekBar"
              class="relative w-full h-full cursor-pointer min-w-min"
              @click="seek"
            >
              <div
                class="absolute top-[50%] left-0 w-full h-px bg-skin-muted"
              ></div>

              <div
                class="absolute top-[50%] left-0 w-full h-px bg-skin-inverse origin-left"
                :style="`transform: scale3d(${currentTime / duration}, 1, 1)`"
              ></div>
            </section>

            <section
              class="text-body-small whitespace-nowrap tabular-nums"
              v-html="total"
            ></section>
          </div>
          <!-- end BLOCK PLAYER CENTER -->

          <!-- BLOCK PLAYER RIGHT -->
          <btn-icon
            :icon="isFullscreen ? 'fullscreen-off' : 'fullscreen-on'"
            title="fullscreen-on-off"
            variant="naked"
            @click="toggle"
            class="ml-md"
          />
          <!-- end BLOCK PLAYER RIGHT -->
        </div>
        <!-- end PLAYER -->
      </footer>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: false,
  },
  cdn: {
    type: String,
    required: false,
    validator: (value) => /^none$|^thron$|^cloudinary$/.test(value),
    default: "none",
  },
  src: {
    type: String,
    required: true,
  },
  width: {
    type: [Number, String],
    required: false,
  },
  height: {
    type: [Number, String],
    required: false,
  },
  aspectRatio: {
    type: String,
    required: false,
    validator: function (value) {
      return (
        ["4 / 5", "1 / 1", "3 / 1", "3 / 2", "16 / 9"].indexOf(value) !== -1
      );
    },
    default: "none",
  },
  useBg: {
    type: Boolean,
    required: false,
    default: true,
  },
  fit: {
    type: String,
    required: false,
    validator: (value) => /^cover$|^contain$/.test(value),
    default: "cover",
  },
  prettyName: {
    type: String,
    required: false,
  },
  originId: {
    type: String,
    required: false,
  },
  poster: {
    type: Object,
    required: false,
  },
  useLoop: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const isHideable = ref(false);
const tpl_video = ref();
const tpl_target = ref(null);
const wasPlaying = ref(true);
const tpl_seekBar = templateRef("tpl_seekBar");

const { fromCloudinary, fromThron } = useCdn();
const finalSrc = ref(props.src);
if (props.cdn === "thron") {
  finalSrc.value = fromThron({
    src: props.src,
    type: "video",
    quality: "WEBHD" /*  WEB, WEBHD o WEBFULLHD */,
  });
}

const { playing, currentTime, duration, muted } = useMediaControls(tpl_video, {
  src: finalSrc.value,
});

muted.value = true;

const { toggle, isFullscreen } = useFullscreen(tpl_video);

const total = computed(() => {
  return `${getMinAndSec(currentTime.value)} / ${getMinAndSec(duration.value)}`;
});

const finalPoster = computed(() => {
  if (!props.poster) {
    return undefined;
  }

  if (props.poster.cdn === "cloudinary") {
    return fromCloudinary(
      props.poster.src,
      Math.min(parseInt(props.width), 1920)
    );
  } else if (props.poster.cdn === "thron") {
    return fromThron({
      contentId: props.poster.src,
      prettyName: props.poster.pretty_name || props.poster.src,
      divArea: Math.min(parseInt(props.width), 1920),
    });
  } else {
    return props.poster.src;
  }
});

const hideControls = ref(false);
const controlsTimeOut = ref();

const {
  sourceType,
  elementX: mouseX,
  elementY: mouseY,
  isOutside,
} = useMouseInElement(tpl_video, { scroll: false });

let observer;

watchOnce(sourceType, (newVal) => {
  isHideable.value = newVal === "mouse";
});

watch([mouseX, mouseY, isOutside], (value) => {
  if (isHideable.value && isOutside.value) {
    if (controlsTimeOut.value) {
      clearTimeout(controlsTimeOut.value);
      controlsTimeOut.value = null;
    }
    hideControls.value = true;
    return;
  }

  handleControlsTimeOut();
  hideControls.value = false;
});

function getMinAndSec(seconds) {
  const min = Math.round(seconds / 60);
  const secs = Math.round(seconds % 60);
  return (
    min.toString().padStart(2, "0") + ":" + secs.toString().padStart(2, "0")
  );
}

function handleControlsTimeOut() {
  if (controlsTimeOut.value) {
    clearTimeout(controlsTimeOut.value);
    controlsTimeOut.value = null;
  }

  if (isHideable.value) {
    controlsTimeOut.value = setTimeout(() => {
      hideControls.value = true;
    }, 4500);
  }
}

useSafeMountedEl(tpl_target, () => {
  currentTime.value = 0;

  let options = {
    root: null,
    rootMargin: "0px",
    threshold: [0, 1],
  };

  observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && wasPlaying.value) {
        playing.value = true;
      } else if (!entry.isIntersecting) {
        playing.value = false;
      }
    });
  }, options);
  observer.observe(tpl_target.value);

  handleControlsTimeOut();
});

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect();
  }
});

function onPlayPauseClick() {
  playing.value = !playing.value;
  wasPlaying.value = playing.value;
}

function seek(event) {
  currentTime.value =
    (event.offsetX / tpl_seekBar.value.offsetWidth) * duration.value;
}
</script>
